import React from "react"
import { Link } from "gatsby"

const BreadCrumb = ({title}) => {
  return (

    <div className="breadcroumb-area bread-bg">
  <div className="container">
    <div className="row">
      <div className="col-lg-12">
        <div className="breadcroumb-title text-center">
          <h1>{title || null}</h1>
          <h6><Link to="/">ΑΡΧΙΚΗ /</Link>{ ` ${title || ''}`}</h6>
        </div>
      </div>
    </div>
  </div>
</div>


  )
}

export default BreadCrumb
