import React, {useState} from "react"
import { Link } from "gatsby"
import { useFormik } from 'formik';

import Layout from "../components/layout"
import BreadCrumb from "../components/breadcrumb"
import {Form} from 'react-bootstrap'
import ModalPost from '../components/modalpost'
import Success from '../components/success'
import {GTMPhoneClick,GTMContactSubmit} from '../lib/gtag'



const Contact = ({pageContext}) => {


  const [reveal,setReveal] = useState(false)


  const validate = values => {
   const errors = {};

     if (!values.email) {
       errors.email = 'Υποχρεωτικό πεδίο';
     } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
       errors.email = 'Λάθος email';
     }

     if (!values.message) {
       errors.message = 'Υποχρεωτικό πεδίο';
     }

     if (!values.name) {
       errors.name = 'Υποχρεωτικό πεδίο';
     }





   return errors;
  };



  const formik = useFormik({
 initialValues: {
   name:'',
   email: '',
   message: ''

 },
 validate,
 onSubmit: (values, {resetForm}) => {

     console.log(values)
      let url = `${process.env.DRUPAL_URL}/wdapi/webform/submit`

      let data = {
      "webform_id": "contact",
      "webform_data": {
        email:values.email,
        name:values.name,
        message:values.message

      }
      }

       fetch(url,{
         method:'POST',
         headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body:JSON.stringify(data)

      }).then(res => res.json()).then(res => {
        console.log(res)
        GTMContactSubmit()
        resetForm({})
        setReveal(true)
      }).catch(err => console.log)



 }
});



  var metainfo = {
  title: pageContext.metainfo? (pageContext.metainfo.title || 'Επικοινωνία') + " | " + process.env.SITE_NAME  : (pageContext.title || 'Επικοινωνία') + " | " + process.env.SITE_NAME,
  description:pageContext.metainfo? pageContext.metainfo.description || '' : '',
  image:'/images/logo.png'
}
  return (
    <Layout metainfo={metainfo}>
    <BreadCrumb title={pageContext.title}/>
    <ModalPost reveal={reveal} setReveal={setReveal}>
      <Success/>
    </ModalPost>

    <div className="contact-section section-padding">
  <div className="container">
    <div className="row">
      <div className="col-lg-6 col-md-12 col-sm-12">
        <div className="section-title">
          <h2>Πως θα μας βρείτε</h2>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6">
            <h5>Στοιχεία Επικοινωνίας</h5>
            <div className="contact-detail">
              <p><i className="las la-mobile" /><b>Τηλέφωνα</b>
                <span><a onClick={()=> GTMPhoneClick()} href="tel:6949873177">694 987 3177</a></span>
                <span><a onClick={()=> GTMPhoneClick()} href="tel:6942073329">694 207 3329</a></span>
              </p>
            </div>
            <div className="contact-detail">
              <p><i className="las la-map-marker" /><b>Διεύθυνση</b>
                <span>Απειρανθου 20, Γαλατσι 11147</span>
                <span>Αττική, Ελλάδα</span>
              </p>
            </div>
            <div className="contact-detail">
              <p><i className="las la-envelope" /><b>Email</b>
                <span><a href="mailto:johndimitropoulos2@gmail.com">johndimitropoulos2@gmail.com</a></span>
              </p>
            </div>
          </div>

        </div>
      </div>
      <div className="col-lg-6">
        <div className="contact-form">
          <h3>Στείλτε μας μήνυμα</h3>
        <Form   onSubmit={formik.handleSubmit}   noValidate >
        <Form.Group >
       <Form.Label htmlFor="name">Όνομα</Form.Label>
       <Form.Control  isInvalid={formik.touched.name && formik.errors.name} onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.name} type="text" id="name" name="name"  />
       <Form.Control.Feedback type="invalid">
        {formik.errors.name}
      </Form.Control.Feedback>
       </Form.Group>

       <Form.Group >
      <Form.Label htmlFor="email">Email</Form.Label>
      <Form.Control  isInvalid={formik.touched.email && formik.errors.email} onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.email} type="email" id="email" name="email"  />
      <Form.Control.Feedback type="invalid">
       {formik.errors.email}
     </Form.Control.Feedback>
      </Form.Group>

      <Form.Group >
     <Form.Label htmlFor="message">Μήνυμα</Form.Label>
     <Form.Control  isInvalid={formik.touched.message && formik.errors.message} onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.message}  as="textarea" cols={30} rows={4} id="message" name="message" placeholder="" />
     <Form.Control.Feedback type="invalid">
      {formik.errors.message}
    </Form.Control.Feedback>
     </Form.Group>



            <button className="btn btn-primary" type="submit" name="submit">ΑΠΟΣΤΟΛΗ ΜΗΝΥΜΑΤΟΣ</button>
          </Form>
        </div>
      </div>
    </div>
  </div>
</div>







    </Layout>
  )
}

export default Contact
